<script lang="ts" setup>
import {buyVip} from "@helpers/utils";
import BannerBottom from "@components/banner-bottom/banner-bottom.vue";
import {BigBigWork} from "global-common"

const isTopOut = ref(false)

onMounted(() => {
  BigBigWork.on(`isTopOut`, handleChangeTop)
})
onUnmounted(() => {
  BigBigWork.off(`isTopOut`, handleChangeTop)
})
const handleBuyVip = () => {
  buyVip({fromText: `页脚`})
}
const handleChangeTop = ( value ) => {
  isTopOut.value = value
}
</script>
<template>
  <div :class="$style[`footer`]">
    <div :class="$style.bg"></div>
    <div :class="$style[`center`]">
      <div :class="$style[`info`]">
        <img :class="$style[`logo`]" :src="`${process.env.CDN_CP_PATH}/img/1/clippng.svg`" alt="clippng logo"/>
        <p :class="$style[`info-desc`]">
          Clippng has tens of millions of PNG images and other graphic resources for everyone to download.Our aim is to build a largest free PNG image platform in the world, serve for all the professional designer and people who have design skills. Helping users save time and improving their projects efficiently is our duty.
        </p>
        <!--pinterest ins 按钮 -->
<!--        <div :class="$style[`social`]">
          <a :class="$style.link" href="#">
            <img :src="`${process.env.CDN_CP_PATH}/img/icon/logo-pin@2x.png`" alt="pinterest"/>
          </a>
          <a :class="$style.link" href="#">
            <img :src="`${process.env.CDN_CP_PATH}/img/icon/logo-ins@2x.png`" alt="ins"/>
          </a>
        </div>-->
      </div>
      <div :class="$style[`site-map`]">
        <!-- Clippng.com -->
        <dl :class="$style[`map`]">
          <dt :class="[$style[`map-title`],`font-bold`]">Clippng.com</dt>
          <dd>
            <ol :class="$style[`list`]">
              <li :class="$style[`li`]"><a href="/help/about-us.html" target="_blank" title="About Us">About Us</a></li>
              <li :class="$style[`li`]"><a href="/search" target="_blank" title="PNG Images">PNG Images</a></li>
            </ol>
          </dd>
        </dl>
        <!-- INFORMATION -->
        <dl :class="$style[`map`]">
          <dt :class="[$style[`map-title`], `font-bold`]">INFORMATION</dt>
          <dd>
            <ol :class="$style[`list`]">
              <li :class="$style[`li`]"><a href="/plan" target="_blank" title="Pricing" @click.prevent="handleBuyVip">Pricing</a></li>
              <li :class="$style[`li`]"><a href="/contact.html" target="_blank" title="Enterprise Plan">Contact us</a></li>
            </ol>
          </dd>
        </dl>
        <!-- LEGAL -->
        <dl :class="$style[`map`]">
          <dt :class="[$style[`map-title`],`font-bold`]">LEGAL</dt>
          <dd>
            <ol :class="$style[`list`]" >
              <li :class="$style[`li`]"><a href="/legal/terms-of-service.html" target="_blank" title="Terms of Service">Terms of Service</a></li>
              <li :class="$style[`li`]"><a href="/legal/privacy-policy.html" target="_blank" title="Privacy Policy">Privacy Policy</a></li>
              <li :class="$style[`li`]"><a href="/legal/copyrights-infringement-notification.html" target="_blank" title="Copyrights Infringement Notification">Copyrights Infringement Notification</a></li>
              <li :class="$style[`li`]"><a href="/legal/cookies-policy.html" target="_blank" title="Cookies Policy">Cookies Policy</a></li>
              <li :class="$style[`li`]"><a href="/legal/intellectual-property-rights.html" target="_blank" title="Intellectual Property Rights">Intellectual Property Rights</a></li>
            </ol>
          </dd>
        </dl>
      </div>
    </div>
    <div :class="$style[`copyright`]">
      <span>©️2023-2025 Clippng-All Rights Reserved.</span>
    </div>
    <BannerBottom :isTopOut="isTopOut"></BannerBottom>
  </div>
</template>
<style lang="scss" module src="./footer-index.scss"></style>
