<script lang="ts" setup>
import { useStoreSale } from "@stores/useStoreSale";
import { storeToRefs } from "pinia";
import closeIcon from "@assets/images/icon-close.svg";
import { TransitionFade } from '@morev/vue-transitions';
import { statOpenPay } from "@helpers/stats"
import { buyVip } from "@helpers/utils";
//#region PROPS
const props = defineProps({
  theme: {
    type: String,
    default: `normal`
  },
  isTopOut: {
    type: Boolean,
    default: false
  }
})
//#endregion

//#region DATA
const { getBottomBannerConfig, setBottomBannerConfig } = useStoreSale();
const { isShowBottomBanner, saleInfo } = storeToRefs(useStoreSale());
const bannerDom = ref<HTMLElement | null>(null);
const active = ref<boolean>(false);
//#endregion

//#region HOOKS
onMounted(()=>{
  onEnter();
})
onActivated( ()=> {
  onEnter();
})

onUnmounted ( ( ) => {
  onLeave();
})
onDeactivated(()=> {
  onLeave();
})
//#endregion

//#region METHODS
const onEnter = () => {
  if ( active.value ) return;
  active.value = true;
  getBottomBannerConfig();
}
const onLeave = () => {
  if ( !active.value ) return;
  active.value = false;
}
const handleClose = () =>{
  setBottomBannerConfig ( false );
}
// 点击banner
const handleClickBanner = ( fromText:string ) => {
  // 没有配置跳转链接的，跳转到付费页
  if ( !saleInfo.value.jump_link ) {
    buyVip ( { fromText } )
  }
}
//#endregion

//#region COMPUTED
const bannerStyle = computed(()=>{
  if ( saleInfo.value.banner_img ) {
    return {
      backgroundImage: `url(${saleInfo.value.banner_img})`
    }
  }
})
//#endregion
</script>
<template>
  <div :class="[$style[`banner-wrapper`], isShowBottomBanner && isTopOut && $style[`bottom-h`]]" ref="bannerDom" v-if="saleInfo.banner_title">
    <transition-fade>
      <div :class="[$style[`banner`],$style[`bottom`], $style[props.theme]]" v-show="isShowBottomBanner && isTopOut">
        <div :class="$style[`close-btn`]" @click="handleClose">
          <inline-svg :src="closeIcon"></inline-svg>
        </div>
        <gc-auto-link :link="saleInfo.jump_link"
                      :class="$style[`banner-wrapper`]"
                      :style="bannerStyle"
                      @click="handleClickBanner(`底部广告条`)"
        >
          <span :class="$style[`banner-text`]">{{saleInfo.banner_title}}</span>
        </gc-auto-link>
      </div>
    </transition-fade>
  </div>
</template>
<style module lang="scss" src="./banner-bottom.scss"></style>
